
//변수
$mainColor : #009efe;
$fontFamliy : NotoSansRegular;
$pinkColor : #e62167;

.transactionContent {
    border: 1px solid $mainColor;
    min-height: 30vh;
    text-align: left;
    border-radius: 20px;
    padding: 14px;
}

.headerPart {
    width: 100%;
    height: 50px;
}

.tranListText {
    float: left;
    color: #888888;
    font-family: NotoSansRegular;
    font-weight: bold;
    font-size: 15px;
}

.underArrowTransaction {
    float: left;
    width: 12px;
    margin-left: 8px;
    margin-top: 9px;
}

.refreshBtn {
    width: 20px;
    height: 20px;
    float: right;
    background-image: url('../img/transaction/refreshBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 5px;
    cursor: pointer;
}

.bodyPart {

}

.leftMenu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 16px;
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        & > p {
            margin-right: 6px;
        }
    }
}
.leftTabBox{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

}
.leftTabText {
    color: #888888;
    font-family: NotoSansRegular;
    font-size: 16px;
    margin: 0;
}

.leftSelectBox {
    width: 130px !important;
    height: 35px !important;
    font-family: NotoSansRegular;
    font-size: 14px !important;
    color: #888888 !important;
    word-break: break-all !important;

}

.leftSelectBox::placeholder  {
    color: #693cff !important;
}

.mainColorBtn {
    background-color: $mainColor;
    color: #fff;
    width: 100%;
    height: 36px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    margin-top: 8px;
}
.mainColorBtnLarge {
    width: 100%;
    background-color: $mainColor;
    color: #fff;
    padding: 6px 12px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-family: NotoSansMedium;
    font-size: 14px;
}
.grayColorBtnLarge {
    width: 100%;
    background-color: #fff;
    color: #666666;
    border: 1px #e1e1e1 solid;
    padding: 6px 12px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-family: NotoSansMedium;
    font-size: 14px;
}

.rightContent {

}

.btnTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
}

.myHisBtn {
    float: right;
    margin-right: 25px;
    cursor: pointer;
}

.selRegBtn {
    float: right;
    margin-right: 0px;
    cursor: pointer;
}

.trContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 0;
    border-top: 1px solid #e2e2e2;

}

.selCoinText {
    color: #888888;
    font-family: NotoSansRegular;
    font-size: 16px;

}

.buyCoinText {
    color: #888888;
    font-family: NotoSansRegular;
    font-size: 16px;

}
.tcText {
    display: none;   
}
.transactionDetailBox {
    width: 100%;
    padding: 6px;
    border: #e2e2e2 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.transcatioin-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sellCoinContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    
}
.coinImage {
    max-width: 32px;
    height: 32px;
}
.coinBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
    & > p {
        margin: 0;
        font-family: $fontFamliy;
    }
}
.arrowBox img{
    width: 16px;
}

.coinName {
    color: #888888;
    font-weight: bold;
    font-size: 14px;
}

.coinAmount {
    color: #693cff;
    font-weight: bold;
    font-size: 16px;
    font-family: $fontFamliy;
}

.coinSymbol {
    color: #888888;
    font-family: NotoSansRegular;
    margin-left: 3px;
}

.coinKo {
    color: #888888;
    font-family: NotoSansRegular;
    font-size: 12px !important;
}
.btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    text-align: center;
    width: 100%;
}
.doTransactionBox {
    width: 105px;
    padding: 5px 0px;
    background-color: $pinkColor;
    color: #fff;
    font-family: NotoSansRegular;
    font-size: 14px;
    cursor: pointer;
    flex: 1;
    padding: 10px;
}
.doCancelTransactionBox {
    width: 105px;
    padding: 5px 0px;
    background-color: #ff7f50;
    color: #fff;
    font-family: NotoSansRegular;
    font-size: 14px;
    cursor: pointer;
}

.completeTransactionBox { 
    padding: 5px 10px;
    background-color: #e1e1e1;
    font-family: NotoSansRegular;
    font-size: 14px;
}

.cancelTransactionBox { 
    padding: 5px 10px;
    background-color: #e1e1e1;
    font-family: NotoSansRegular;
    font-size: 14px;
}

.registData {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    & > p{
        margin-top: 4px;
        margin-bottom: 0;
    }
}

.registUser { 
    font-family: NotoSansRegular;
    font-size: 12px !important;
    color: #888888;
    margin-left: 22px;

}

.registDate {     
    font-family: NotoSansRegular;
    font-size: 12px !important;
    color: #888888;

}

.selOption {
    word-break: break-all;
}

.selImg {
    max-width: 25px;
    max-height: 25px;
    margin-right: 5px;
}

.selName {
    font-family: NotoSansRegular;
    font-size: 13px !important;
    color: #888888 !important;
    word-break: break-all !important;
}

.selBox::placeholder{
    color: #693cff !important;
}

.leftSelectBox::placeholder{
    color: #693cff !important;
}

/// myTransaction
.buyListBtn {
    float: right;
    width: 105px;
    height: 100px;
    background-image: url('../img/transaction/buyListBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-right: 25px;
}

.sellListBtn {
    float: right;
    width: 105px;
    height: 100px;
    background-image: url('../img/transaction/sellListBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-right: 25px;
}

// modal popup
.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
}
.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modal > section {
    width: 80%;
    max-width: 700px;
    margin:0 auto;
    border-radius: .6rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show .3s;
    overflow: hidden;
}

.modalHeader {
    width: 100%;
    text-align: right;
    padding: 10px 20px 0 10px;
}

.exitBtn {
    width: 14px;
    height: 14px;
    background-image: url('../img/transaction/exitBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
}
.modal > section > main {
    padding: 0px 10px 10px 10px;
    text-align: center;
}

.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show .3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.doTransaction {
    padding: 10px 0px;

}

.exMark {
    margin-bottom: 15px;
}

.warnText {
    font-family: NotoSansRegular;
    font-size: 14px;
    color: #888888;
    text-align: center;
    word-break: keep-all;
}

.buyConfirmBtn {
    margin-top: 30px;
    display: flex;
    align-items: center;       
}   
.cancelBtn {
    width: 100px;
    height: 33px;
    background-image: url('../img/transaction/cancelBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 30px;
}

.confirmBtn {
    width: 100px;
    height: 33px;
    background-image: url('../img/transaction/confirmBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.warringModal {
    padding: 80px 0px;
}

.errText {
    font-family: NotoSansRegular;
    font-size: 14px !important;
    color: #888888 !important;
}

.regCelCoinTextBox {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 4px;
    font-family: NotoSansRegular;
    font-size: 14px !important;
    color: #888888 !important;
}

.regCelCoinText {

}

.selCoinBox {
    text-align: left;
}

.warringBtn {
    margin-top: 113px;
}

.coinSelBox {
    width: 35% !important;
}

.selCoinAmount {
    width: 60% !important;
}

.getCoinAmount {
    width: 60% !important;
}

.coinRegRuleText {
    font-family: NotoSansRegular;
    font-size: 14px !important;
    color: #888888 !important;
    display: inline-block;
    margin-top: 20px;
}

.regSelBtnDiv {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    background-color: $pinkColor;
    color: #fff;
    line-height: 36px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
}

.regSelBtn {
    width: 100px;
    height: 33px;
    background-image: url('../img/transaction/regSelBtn.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.selRegDetail{
    padding: 0px 15px;
}
.coinTrans{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selCoinTrans {
    flex: 1;
    display: inline-block;
    vertical-align: top;
    margin: 10px;
    text-align: center;
}

.getCoinTrans {
    flex: 1;
    display: inline-block;
    vertical-align: top;
    margin: 10px;
    text-align: center;
}

.selDiv {
    border: #e2e2e2 1px solid;
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
}

.getDiv {
    border: #e2e2e2 1px solid;
    padding: 10px;
    margin-top: 15px;
    border-radius: 10px;
}

.coinTransText {
    font-family: NotoSansRegular;
    font-size: 16px !important;
    color: #888888 !important;
}

.showFeeAmount {
    display: inline-block;
    color: #ffffff;
    font-family: NotoSansRegular;
    font-size: 14px;
    padding: 10px;
    border-radius: 10px;
    margin: 15px 0;
    background-color: #693cff;
}

.transDetailImage {
    max-width: 40px;
    height: 40px;
}

.transDetailKind {
}


.transDetailCost {
    font-family: NotoSansMedium;
    font-size: 20px !important;
    color: #693cff !important;
}

.transDetailName {
    font-family: NotoSansRegular;
    font-size: 16px !important;
    color: #888888 !important;
}

.transDetailSymbol {
    font-family: NotoSansRegular;
    font-size: 14px !important;
    color: #888888 !important;
}

.selRegConfirmBtn {  
    margin-top: 20px;
    display: flex;
    align-items: center;
    &>div {
        margin: 0;
    }
}

.backBtn2 {
    width: 17px;
    height: 17px;
    background-image: url('../img/transaction/backBtn.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-color: transparent;
    float: left;
    margin-top: -20px;
    border: 0px;
}

.mytran-header-container {
    height: 100px;
}

.mytran-header-button-wrapper {
    float: right;
    display: flex;
    align-items: center;
    width:100%;
    margin: 12px 0;
}
.mytran-list-container {
}
.mytran-list-item-container {
    padding: 10px;
    border: 1px #e1e1e1 solid;
    border-radius: 20px;
    margin: 0px 0px 20px 0px;
    text-align: left;
}
.mytran-list-item-detail {
    text-align: left;
    font-family: NotoSansRegular;
    font-size: 16px;
}
.mytran-list-item-status {
    text-align: center;
}
.mytran-list-item-coin {
    margin: 8px 0px;
}
.mytran-list-item-coin-num1 {
    font-family: NotoSansMedium;
    color: #693cff;
    font-size: 18px;
}
.mytran-list-item-coin-num2 {
    font-family: NotoSansMedium;
    color: #ff7f50;
    font-size: 18px;
}
.mytran-list-item-date {
    margin: 8px 0px;
    font-family: NotoSansRegular;
    color: #999999;
    font-size: 14px;
}
.mytran-list-item-fee {
    margin: 8px 0px;
    font-family: NotoSansRegular;
    color: #999999;
    font-size: 14px;
    
}
.mytran-list-item-symbol {
    font-family: NotoSansRegular;
    color: #999999;
    font-size: 14px;
}
.mytran-list-item-status-text {
    font-family: NotoSansRegular;
    color: #999999;
    font-size: 16px;
}
.mytran-list-item-status-cancel {
    background-color: #777;
    font-family: NotoSansMedium;
    color: #fff;
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
}
.mytran-top-refresh {
    float: right;
    cursor: pointer;
}
.mytran-header-back-wrapper {
    float: left;
    cursor: pointer;
}

