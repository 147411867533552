@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 100;
  src: url("./css/fonts/NotoSansKR-Light.woff2") format('woff2'),
  url("./css/fonts/NotoSansKR-Light.woff") format('woff'),
  url("./css/fonts/NotoSansKR-Light.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 300;
  src: url("./css/fonts/NotoSansKR-Regular.woff2") format('woff2'),
  url("./css/fonts/NotoSansKR-Regular.woff") format('woff'),
  url("./css/fonts/NotoSansKR-Regular.otf") format('truetype')
}


@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: url("./css/fonts/NotoSansKR-Medium.woff2") format('woff2'),
  url("./css/fonts/NotoSansKR-Medium.woff") format('woff'),
  url("./css/fonts/NotoSansKR-Medium.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 600;
  src: url("./css/fonts/NotoSansKR-Bold.woff2") format('woff2'),
  url("./css/fonts/NotoSansKR-Bold.woff") format('woff'),
  url("./css/fonts/NotoSansKR-Bold.otf") format('truetype')
}

body {
  margin: 0;
  font-family: 'Noto Sans CJK KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color:#888;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  background-color: #fff;
  input{
    font-family: 'Noto Sans CJK KR';
    font-size: 16px;
    font-weight: 300;
    color:#000;
    &::placeholder{
        font-family: 'Noto Sans CJK KR';
        font-weight: 300;
        font-size: 14px;
        color: #888;
    }
    &:focus{
        outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
        transition: background-color 5000s ease-in-out 0s !important;
        -webkit-text-fill-color: #000 !important;
    }
  }
  button {
    font-family: 'Noto Sans CJK KR';
  }
}
